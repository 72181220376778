import { Auth } from 'aws-amplify';

const getDefaultState = () => {
  return {
    email: null,
    code: null,
    loggedIn: null,
    session: null,
  }
}

const state = getDefaultState();

const getters = {
  stateEmail: state => state.email,
  loggedIn: state => state.loggedIn,
  session: state => state.session,
};

const actions = {
  async signin({commit}, email) {
    this.cognitoUser = await Auth.signIn(email);
    if (this.cognitoUser) {
      commit('setemail', email);
    }
  },
  async answer({commit}, code) {
    this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, code);
    if (this.cognitoUser) {
      commit('setcode', code);
    }
    // add the identity id attribute if it isn't there already
    const user = await Auth.currentAuthenticatedUser();
    if (!user.attributes["custom:identityid"]) {
      Auth.currentCredentials().then(credentials => {
        const identityId = credentials.identityId

        Auth.updateUserAttributes(user, {
          'custom:identityid': identityId
        }).then(() => {
          console.log("Added identityId successfully")
        }).catch(err => {
          console.log("Error updating custom attribute: " + JSON.stringify(err))
        })
      })
    }
  },
  async signout({commit}) {
    await Auth.signOut();
    commit('resetState');
  },
  async isAuthenticated({commit}) {
    if (this.cognitoUser != null) {
      this.cognitoUser.getSession((err, session) => {
      if (err) {
        commit('loggedIn', false)
      }
        commit('loggedIn', true)
        commit('session', session)
      })
    } else {
      commit('loggedIn', false)
    }
  },
  resetState ({ commit }) {
    commit('resetState')
  },
};

const mutations = {
  setemail(state, email) {
    state.email = email
  },
  setcode(state, code) {
    state.code = code
  },
  loggedIn(state, loggedIn) {
    state.loggedIn = loggedIn
  },
  session(state, session) {
    state.session = session
  },
  signout(state) {
      state.email = null
      state.code = null
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

/* Project Light blue theme colours
 	#002e55 dark highlight
	#003e74 page header colours/global footer
	#004e8f carousel btn hover
	#004e8f main local nav bar
	#005dab	lighter highlight
	#0072cf selected state/carousel text bg
	#68ace5 pale tone - lightest highlight/footer headings
	#d2e4f3 pale table bg
*/

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#004e8f',
        secondary: '#3b977a',
      },
    },
  },
});

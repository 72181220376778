import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import cognitoAuth from './plugins/cognito'

import "core-js/stable";
import "regenerator-runtime/runtime";

import "@/assets/global.css"


Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  cognitoAuth,
  render: h => h(App)
}).$mount('#app')

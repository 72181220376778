<template>
  <v-app>
    <v-system-bar color="#002e55" app/>
    <NavBar />
    <v-main>

      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer height="50" app>
      <FooterLinks />
    </v-footer>
  </v-app>
</template>

<script>
import config from "@/config";
import Amplify from "aws-amplify";
Amplify.configure({
  aws_cognito_region: config.region,
  aws_user_pools_id: config.userPoolId,
  aws_user_pools_web_client_id: config.clientId,
  aws_cognito_identity_pool_id: config.identityPoolId,
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.clientId,
  },
  Storage: {
    AWSS3: {
        bucket: config.bucketName,
        region: config.region
    }
  }
});

export default {
  components: {
    NavBar: () => import("@/components/NavBar.vue"),
    FooterLinks: () => import("@/components/FooterLinks.vue")
  },
  name: 'App',
  mounted() {
    document.title = "Deposit Service";
  },
  data: () => ({
    //
  }),
};
</script>

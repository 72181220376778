<template>
<v-container>
  <v-row align="center">
    <v-col align="center">
      <v-card align="left" max-width="400" class="pa-4" tile>
        <v-img src="@/assets/cul_colour.png"></v-img>
        <template v-if="!this.emailEntered">
          <v-card-title><v-icon size="64" class="me-4">mdi-email-fast</v-icon>Request Sign-in Code</v-card-title>
          <v-card-subtitle>Enter your email address to receive a one-time code to sign in to the Deposit Service.</v-card-subtitle>
          <v-form @submit.prevent="submit" v-model="isEmailValid">
            <v-text-field v-model="email" :rules="emailRules" label="Email address">
              <input type="email" email name="email" autocomplete="email" />
            </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn x-large block color="secondary" class="white--text" :disabled="!isEmailValid" @click="getEmail">
              <div>
                <span title="Send me a sign in code">SEND ME A SIGN-IN CODE</span>
              </div>
              <v-progress-circular indeterminate color="primary" v-if="busy"></v-progress-circular>
            </v-btn>
            <p v-if="error">{{ error }}</p>
          </v-card-actions>
          </v-form>
          <v-container>
            <v-row align="center">
              <v-divider class="mx-4"></v-divider>or<v-divider class="mx-4"></v-divider>
            </v-row>
          </v-container>
          <v-container class="text-center">
            <v-btn x-large block @click="this.$cognitoAuth.redirectToSignin">
              <v-img src="@/assets/raven-logo-small.gif" class="mx-3" alt="Raven logo" max-height="32px" max-width="32px"></v-img>
                <span title="Staff login">Staff login</span>
            </v-btn>
          </v-container>
        </template>
        <template v-else>
          <v-card-title>Sign in</v-card-title>
          <v-card-subtitle>
            If your email address is registered with the service, you will receive a one-time code in the next few minutes - please enter that code below then click 'Continue'
          </v-card-subtitle>
          <v-form @submit.prevent="submit" v-model="codeEntered">
            <v-otp-input class="ma-2" required length="6" type="number" v-model="code" @on-change="handleOnChange" @on-complete="handleOnComplete">
            </v-otp-input>
            <v-card-actions>
              <v-btn class="ma-2" x-large @click="restart">
                <v-icon>mdi-arrow-left</v-icon>
                <span title="Start over">Start over</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn x-large class="ma-2 white--text" color="secondary" :disabled="code.length < 6" @click="getCode">
                <div v-if="!(busy)">
                  <span title="Continue">CONTINUE</span>
                </div>
                <v-progress-circular indeterminate v-if="busy" color="primary"></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-form>
        </template>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SignIn",
  components: {},
  data: function () {
    return {
      busy: false,
      email: null,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      emailEntered: false,
      codeEntered: false,
      isEmailValid: false,
      code: '',
      error: null
    }
  },
  methods: {
    ...mapActions(['signin', 'answer', 'resetState']),
    handleOnComplete(value) {
      this.codeEntered = true;
      this.code = value;
    },
    handleOnChange(value) {
      this.code = value;
    },
    async getEmail() {
      try {
        this.busy = true;
        this.emailEntered = true;
        await this.signin(this.email);
        this.error = "";
      } catch (error) {
        this.error = error;
      }
      this.busy = false;
    },
    async getCode() {
      try {
        this.busy = true;
        await this.answer(this.code);
        this.$router.push("/deposits");
      } catch (error) {
        this.error = error;
      }
      this.busy = false;
    },
    async restart() {
      this.busy = true;
      this.resetState();
      this.email = null;
      this.emailEntered = false;
      this.codeEntered = false;
      this.isEmailValid = false;
      this.busy = false;
      this.error = "";
    },
  }
}
</script>

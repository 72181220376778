import config from "@/config"
import { Auth } from "aws-amplify"
import * as AWS from "aws-sdk"

const getDefaultState = () => {
  return {
    users: [],
    loading: false,
  }
}

const state = getDefaultState()

const getters = {
  users: (state) => state.users,
  loading: (state) => state.loading,
  user: (state) => (sub) => {
    return state.users.find(user => user.sub === sub)
  }
};

const actions = {
  async getUsers({ commit }) {
    commit('setLoading', true); // Use commit directly
    const client = await Auth.currentCredentials().then(credentials => {
      return new AWS.CognitoIdentityServiceProvider({
        region: config.region,
        credentials: credentials
      });
    });

    let params = {
      UserPoolId: config.userPoolId,
      Filter: 'status = "Enabled"',
      Limit: 60 // AWS Cognito limit for list users
    };

    let allUsers = []; // Initialize an array to hold all users

    try {
      let response;

      do {
        response = await client.listUsers(params).promise();
        let cookedUsers = response.Users.map(user => {
          user.Attributes.forEach(attr => {
            user[attr.Name] = attr.Value;
          });
          delete user.Attributes;
          if (!user.identities) {
            user.displayName = user.name ? user.name : user.email;
            return user; // Return the modified user
          }
          return null; // Exclude federated users
        }).filter(Boolean); // Remove nulls

        allUsers = allUsers.concat(cookedUsers); // Merge with existing users

        // Check for a pagination token to fetch more users
        params.PaginationToken = response.PaginationToken;
      } while (params.PaginationToken); // Continue until there's no more token

      commit('setUsers', allUsers); // Commit all users
    } catch (err) {
      console.log(err);
    } finally {
      commit('setLoading', false); // Ensure loading ends
    }
  },
  startLoading({ commit }) {
    commit('setLoading', true);
  },
  endLoading({ commit }) {
    commit('setLoading', false);
  },
  resetState({ commit }) {
    commit('resetState');
  },
};


const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  },
  addUser(state, user) {
    state.users.push(user);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

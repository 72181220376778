<template>
<v-container>
  <v-row align="center">
    <v-col align="center">
      <v-card align="left" tile max-width="400" max-height="300">

          <v-card-title><v-icon>mdi-lock</v-icon>Answer Security Challenge</v-card-title>
          <v-card-subtitle>Please enter the secret sign-in code that was 
            sent to your email address to complete sign-in</v-card-subtitle>

          <v-form @submit.prevent="submit">
            <v-otp-input class="ma-2" required length="6" type="number" v-model="code">
            </v-otp-input>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="ma-2" color="primary" type="submit">
                <div v-if="!(busy)">CONTINUE</div>
                <v-progress-circular indeterminate v-if="busy" color="primary"></v-progress-circular>
              </v-btn>
            <p v-if="error">{{ error }}</p>
            </v-card-actions>
          </v-form>

      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AnswerChallenge",
  components: {},
  data: function () {
    return {
      busy: false,
      code: "",
      error: ""
    }
  },
  methods: {
    ...mapActions(["answer"]),
    async submit() {
      this.busy = true;
      try {
        this.error = "";
        await this.answer(this.code);
        this.$router.push("/deposits");
      } catch (error) {
       this.error = error;
      }
    }
  }
}
</script>


<style scoped>
.digit-input {
    text-align: center;
    font-size: 1.7em;
}
</style>

<template>
  <v-container>
    <v-img src="@/assets/cul_colour.png" class="logo" contain position="left top"></v-img>
    <BeforeYouStart />
    <v-btn
      v-if="$routerHistory.hasPrevious()"
      :to="{ path: $routerHistory.previous().path }">
      GO BACK
    </v-btn>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
  }),
  components: {
    BeforeYouStart: () => import("@/components/BeforeYouStart.vue")
  }
}
</script>

<style>
.logo {
  margin-bottom: 20px;
  max-height: 50px;
}
</style>

export default {
  environment: process.env.VUE_APP_ENVIRONMENT,
  region: process.env.VUE_APP_REGION,
  identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
  userPoolId: process.env.VUE_APP_USER_POOL_ID,
  clientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
  bucketName: process.env.VUE_APP_BUCKET_NAME,
  cognitoDomain: process.env.VUE_APP_COGNITO_DOMAIN,
  identityProvider: process.env.VUE_APP_IDENTITY_PROVIDER_NAME,
  redirectUri: process.env.VUE_APP_REDIRECT_URI,
  skipIntroName: process.env.VUE_APP_SKIP_INTRO_NAME
}

<template>
  <v-container>
    <v-img src="@/assets/cul_colour.png" class="logo" contain position="left top"></v-img>
    <HelpPage />
    <v-btn
      v-if="$routerHistory.hasPrevious()"
      :to="{ path: $routerHistory.previous().path }">
      GO BACK
    </v-btn>
  </v-container>
</template>

<script>
  export default {
    components: {
      HelpPage: () => import("@/components/HelpPage.vue")
    }
  }
</script>

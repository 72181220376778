<template>
  <v-container>
    <v-img src="@/assets/cul_colour.png" class="logo" contain position="left top"></v-img>
    <WelcomePage />
  </v-container>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
  }),
  methods: {
    cancelUpload () {
      this.dialog = false
    }
  },
  components: {
    WelcomePage: () => import("@/components/WelcomePage.vue")
  }
}
</script>

<style>
.logo {
  margin-bottom: 20px;
  max-height: 50px;
}
</style>

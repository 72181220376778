<template>
  <v-container>
    <DepositsSidebar :deposits="[]" />
    <DepositFileList />
    <UploadFiles v-if="ownsActiveDeposit" />
    <AdminUnownedDeposit v-else />
    <v-btn
      v-if="$routerHistory.hasPrevious()"
      :to="{ path: $routerHistory.previous().path }">
      GO BACK
    </v-btn>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data: () => ({
    dialog: false,
    deposits: [],
    activeDeposit: null,
    openDeposit: false,
    myIdentityId: ''
  }),
  props: {
    depositor: String, // the identity id of the depositor who owns this deposit
  },
  methods: {
    cancelUpload () {
      this.dialog = false
    },
  },
  components: {
    DepositFileList: () => import("@/components/DepositFileList.vue"),
    UploadFiles: () => import("@/components/UploadFiles.vue"),
    DepositsSidebar: () => import("@/components/DepositsSidebar.vue"),
    AdminUnownedDeposit: () => import("@/components/AdminUnownedDeposit.vue")
  },
  created() {
    Auth.currentCredentials().then((user) => {
      this.myIdentityId = user.identityId
    })
  },
  computed: {
    ownsActiveDeposit() {
      return (this.depositor == this.myIdentityId) ? true : false
    }
  },
}
</script>

<style>
.logo {
  max-height: 80px;
}
</style>

import axios from "axios";

const getDefaultState = () => {
  return {
    deposits: null,
    activeDeposit: null
  }
}

const state = getDefaultState()

const getters = {
  deposits: (state) => state.deposits,
  activeDeposit: (state) => state.activeDeposit
};

const actions = {
  async getDeposits({ commit }) {
    let response = await axios.get("deposits");
    commit("setDeposits", response.data);
  },
  resetState ({ commit }) {
    commit('resetState')
  },
};

const mutations = {
  setDeposits(state, deposits) {
    state.deposits = deposits;
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

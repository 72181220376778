<template>
  <v-card>
    <v-card-title>{{ user.username }}</v-card-title>
    <v-card-actions>
      <v-btn @click="signout" color="danger">Sign out</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'ProfilePage',
    data () {
      return {
        user: {},
        accessToken: null,
        error: null
      }
    },
    created: function() {
      this.user = this.$cognitoAuth.getCurrentUser();
      this.$cognitoAuth.getIdToken((err, result) => {
        if (err) {
          this. error = err
        } else {
          this.accessToken = result
        }
      })
    },
    methods: {
        signout: function() {
            this.$cognitoAuth.signOut()
            this.$router.push({path: '/'})
        }
    }
  }
</script>

<template>
  <v-container>
    <DepositList :deposits="sortedDeposits" />
    <DepositsSidebar :deposits="sortedDeposits" />
  </v-container>
</template>

<script>
import config from "@/config";
import { Auth } from "aws-amplify";
import * as AWS from "@aws-sdk/client-s3";
import _ from "lodash";

export default {
  data: () => ({
    dialog: false,
    deposits: []
  }),
  computed: {
    sortedDeposits() {
      return _.orderBy(this.deposits, ['status', 'createdDate'], ['desc', 'desc'])
    }
  },
  methods: {
    cancelUpload () {
      this.dialog = false
    },
    readAllData: function(results) {
      var metadataFileContents = [];
      results.forEach(result => {
        const fileKey = result.Key
        Auth.currentCredentials().then(credentials => {
          const client = new AWS.S3({
            region: config.region,
            credentials: Auth.essentialCredentials(credentials)
          });

          return client
        }).then(client => {
          const params = {
            Bucket: config.bucketName,
            Key: fileKey
          }

          client.getObject(params).then(
            (data) => {
              return data.Body
            },
            (err) => {
              console.log(err)
            }
          ).then(stream => {
            // disgusting boilerplate taken from https://github.com/aws/aws-sdk-js-v3/issues/1877#issuecomment-764404713
            const streamToString = (stream) => {
              return new Promise((resolve, reject) => {
                if (stream instanceof ReadableStream === false) {
                  reject(
                    "Expected stream to be instance of ReadableStream, but got " +
                      typeof stream
                  );
                }
                let text = "";
                const decoder = new TextDecoder("utf-8");
                const reader = stream.getReader();
                const processRead = ({ done, value }) => {
                  if (done) {
                    // resolve promise with chunks
                    // resolve(Buffer.concat(chunks).toString("utf8"));
                    resolve(text);
                    return;
                  }
                  text += decoder.decode(value);
                  // Not done, keep reading
                  reader.read().then(processRead);
                };
                // start read
                reader.read().then(processRead);
              });
            };
            return streamToString(stream)
          }).then(streamAsString => {
            metadataFileContents.push(JSON.parse(streamAsString))
          })
        })
      });
      return metadataFileContents
    },
    getData: function() {
      Auth.currentCredentials().then(credentials => {
        const client = new AWS.S3({
          region: config.region,
          credentials: Auth.essentialCredentials(credentials)
        });

        return [client, credentials.identityId]
      }).then(clientAndIdentityId => {
        const client = clientAndIdentityId[0]
        const identityId = clientAndIdentityId[1]
        const params = {
          Bucket: config.bucketName,
          Prefix: "private/" + identityId + "/deposits/"
        }

        client.listObjectsV2(params).then(
          (data) => {
            var contents = data.Contents;
            if (contents) {
              this.deposits = this.readAllData(contents)
            } else {
              this.deposits = []
            }
          },
          (err) => {
            console.log(err)
          }
        )
    })
    },
  },
  components: {
    DepositList: () => import("@/components/DepositList.vue"),
    DepositsSidebar: () => import("@/components/DepositsSidebar.vue")
  },
  created() {
    this.getData();
  },
  mounted () {
    this.$root.$on('refresh', refresh => {
      if (refresh) {
        this.getData()
      }
    })
  }
}
</script>


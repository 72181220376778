import { Config, CognitoIdentityCredentials } from 'aws-sdk'
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js'

import config from '@/config'

export default class CognitoAuth {
  constructor () {
    this.userSession = null
    this.tokens = null
  }

  configure (config) {
    if (typeof config !== 'object' || Array.isArray(config)) {
        throw new Error('[CognitoAuth error] valid option object required')
    }
    this.userPool = new CognitoUserPool({
        UserPoolId: config.userPoolId,
        ClientId: config.clientId
    })
    Config.region = config.region
    Config.credentials = new CognitoIdentityCredentials({
        IdentityPoolId: config.identityPoolId
    })
    this.options = config
  }

  isAuthenticated (cb) {
      let cognitoUser = this.getCurrentUser()
      if (cognitoUser != null) {
          cognitoUser.getSession((err, session) => {
          if (err) {
              return cb(err, false)
          }
          return cb(session, true)
          })
      } else {
          cb(null, false)
      }
  }

  authenticate (username, pass, cb) {
    let authenticationData = { Username: username, Password: pass }
    let authenticationDetails = new AuthenticationDetails(authenticationData)
    let userData = { Username: username, Pool: this.userPool }
    let cognitoUser = new CognitoUser(userData)

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            var logins = {}
            logins['cognito-idp.' + config.region + '.amazonaws.com/' + config.userPoolId] = result.getIdToken().getJwtToken()
            
            Config.credentials = new CognitoIdentityCredentials({
                IdentityPoolId: config.identityPoolId,
                Logins: logins
            })
            cb(null, result)
        },
        onFailure: function (err) {
            cb(err);
        }
    })
  }

  getCurrentUser () {
    return this.userPool.getCurrentUser()
  }

  getUserAttributes (cb) {
    let cognitoUser = this.getCurrentUser()
      if (cognitoUser != null) {
          cognitoUser.getUserAttributes((err, attrs) => {
          if (err) {
              return cb(err, false)
          }
          return cb(attrs, true)
          })
      } else {
          cb(null, false)
      }
  }

  signOut () {
    this.getCurrentUser().signOut()
    localStorage.removeItem('tokens')
  }

  getIdToken (cb) {
    if (this.getCurrentUser() == null) {
      return cb(null, null)
    }
    this.getCurrentUser().getSession((err, session) => {
      if (err) return cb(err)
      if (session.isValid()) {
        return cb(null, session.getIdToken().getJwtToken())
      }
      cb(Error('Session is invalid'))
    })
  }

  sha256 = async (str) => {
    return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
  };

	generateNonce = async () => {
    const hash = await this.sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    const hashArray = Array.from(new Uint8Array(hash));
    return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
  };

	base64URLEncode = (string) => {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "")
  };

  calculateClockDrift = (iatAccessToken, iatIdToken) => {
    const now = Math.floor(new Date() / 1000)
    const iat = Math.min(iatAccessToken, iatIdToken)
    return now - iat
  }

  redirectToSignin = async () => {
    const state = await this.generateNonce();
    const codeVerifier = await this.generateNonce();
    sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier);
    const codeChallenge = this.base64URLEncode(await this.sha256(codeVerifier));
    window.location.href =  `https://${config.cognitoDomain}/oauth2/authorize?response_type=code&client_id=${config.clientId}&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${config.redirectUri}`;
  };

  fetchTokens = async (code, state) => {
    if (code !== null) {
      window.history.replaceState({}, document.title, "/");
      const codeVerifier = sessionStorage.getItem(`codeVerifier-${state}`);
      sessionStorage.removeItem(`codeVerifier-${state}`);
      if (codeVerifier === null) {
        throw new Error("Unexpected code in response");
      }
      const res = await fetch(`https://${config.cognitoDomain}/oauth2/token`, {
        method: "POST",
        headers: new Headers({"content-type": "application/x-www-form-urlencoded"}),
        body: Object.entries({
          "grant_type": "authorization_code",
          "client_id": config.clientId,
          "code": code,
          "code_verifier": codeVerifier,
          "redirect_uri": config.redirectUri
        }).map(([k, v]) => `${k}=${v}`).join("&"),
      });
      if (!res.ok) {
        throw new Error(await res.json());
      }
      await res.json().then( (tokens) => {
        const idTokenData = this.decodePayload(tokens['id_token'])
        const accessTokenData = this.decodePayload(tokens['access_token'])
        localStorage.setItem('tokens', JSON.stringify(tokens))
        localStorage.setItem('CognitoIdentityServiceProvider.'+config.clientId+'.LastAuthUser', idTokenData['cognito:username'])
        localStorage.setItem('CognitoIdentityServiceProvider.'+config.clientId+'.'+idTokenData['cognito:username']+'.idToken', tokens['id_token'])
        localStorage.setItem('CognitoIdentityServiceProvider.'+config.clientId+'.'+idTokenData['cognito:username']+'.accessToken', tokens['access_token'])
        localStorage.setItem('CognitoIdentityServiceProvider.'+config.clientId+'.'+idTokenData['cognito:username']+'.refreshToken', tokens['refresh_token'])
        localStorage.setItem('CognitoIdentityServiceProvider.'+config.clientId+'.'+idTokenData['cognito:username']+'.clockDrift', ''+this.calculateClockDrift(accessTokenData['iat'], idTokenData['iat'])+'')
      })

    } else {
      if (localStorage.getItem("tokens")) {
        // eslint-disable-next-line no-unused-vars
        this.tokens = JSON.parse(localStorage.getItem("tokens"));
      } else {
        this.redirectToSignin();
      }
    }
  }

  decodePayload = (jwtToken) => {
    const payload = jwtToken.split('.')[1]
    try {
      return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'))
    } catch (err) {
      console.log(err)
      return {}
    }
  }
}

CognitoAuth.install = function (Vue, options) {
  Object.defineProperty(Vue.prototype, '$cognitoAuth', {
    get () { return this.$root._cognitoAuth }
  })

  Vue.mixin({
    beforeCreate () {
      if (this.$options.cognitoAuth) {
        this._cognitoAuth = this.$options.cognitoAuth
        this._cognitoAuth.configure(options)
      }
    }
  })
}

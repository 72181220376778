import Vue from 'vue'
import VueRouter from 'vue-router'

import cognitoAuth from '@/plugins/cognito'

import DepositHome from '@/views/DepositHome.vue'
import DepositFileHome from '@/views/DepositFileHome.vue'
import AdminHome from '@/views/AdminHome.vue'
import WelcomePage from '@/views/WelcomePage.vue'
import HelpPage from '@/views/HelpPage.vue'
import BeforeYouStart from '@/views/BeforeYouStart.vue'
import DataProtection from '@/views/DataProtection.vue'
import ProfilePage from '@/components/ProfilePage'
import SignIn from '@/components/SignIn.vue'
import AnswerChallenge from '@/components/AnswerChallenge.vue'
import UserAdmin from '@/views/UserAdmin.vue'

import VueRouterBackButton from 'vue-router-back-button'

Vue.use(VueRouter)

// eslint-disable-next-line no-unused-vars
function requireAuth (to, from, next) {
  cognitoAuth.isAuthenticated((err, loggedIn) => {
    if (err) return next()
    if (!loggedIn) {
      next({
        path: '/signin',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  })
}

function authCallback() {
  const searchParams = new URL(location).searchParams;
  cognitoAuth.fetchTokens(searchParams.get('code'), searchParams.get('state')).then(router.push({name: 'AdminHome'}))
}

// eslint-disable-next-line no-unused-vars
function signOut(to, from) {
  cognitoAuth.signOut()
  router.push({name: "SignIn"})
}

const routes = [
  {
    path: '/',
    name: 'WelcomePage',
    component: WelcomePage,
    alias: '/home',
    meta: {
      breadCrumb: [
        {
          text: 'Deposit Service'
        }
      ]
    }
  },
  {
    path: '/help',
    name: 'HelpPage',
    component: HelpPage,
    meta: {
      breadCrumb: [
        {
          to: { name: 'WelcomePage' },
          text: 'Deposit Service'
        },
        {
          text: 'Help'
        }
      ]
    }
  },
  {
    path: '/beforeyoustart',
    name: 'BeforeYouStart',
    component: BeforeYouStart,
    meta: {
      breadCrumb: [
        {
          to: { name: 'WelcomePage' },
          text: 'Deposit Service'
        },
        {
          text: 'Before You Start'
        }
      ]
    }
  },
  {
    path: '/dataprotection',
    name: 'DataProtection',
    component: DataProtection,
    meta: {
      breadCrumb: [
        {
          to: { name: 'WelcomePage' },
          text: 'Deposit Service'
        },
        {
          text: 'Data Protection'
        }
      ]
    }
  },
  {
    path: '/admin',
    name: 'AdminHome',
    component: AdminHome,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        {
          to: { name: 'WelcomePage' },
          text: 'Deposit Service'
        },
        {
          text: 'Admin Homepage'
        }
      ]
    }
  },
  {
    path: '/me',
    name: 'ProfilePage',
    component: ProfilePage,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        {
          to: { name: 'WelcomePage' },
          text: 'Deposit Service'
        },
        {
          text: 'My Profile'
        }
      ]
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
    meta: {
      breadCrumb: [
        {
          to: { name: 'WelcomePage' },
          text: 'Deposit Service'
        },
        {
          text: 'Sign-in'
        }
      ]
    }
  },
  {
    path: '/answer-challenge',
    name: 'AnswerChallenge',
    component: AnswerChallenge
  },
  {
    path: '/auth',
    name: 'authCallback',
    beforeEnter: authCallback
  },
  {
    path: '/signout',
    name: 'signOut',
    beforeEnter: signOut
  },
  {
    path: '/users',
    name: 'Users',
    component: UserAdmin,
    meta: {
      breadCrumb: [
        {
          to: { name: 'WelcomePage' },
          text: 'Deposit Service'
        },
        {
          text: 'Users'
        }
      ]
    }
  },
  {
    path: '/deposits',
    name: 'DepositHome',
    component: DepositHome,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        {
          to: { name: 'WelcomePage' },
          text: 'Deposit Service'
        },
        {
          text: 'Your deposit area'
        }
      ]
    }
  },
  {
    path: '/deposits/:deposit_id',
    name: 'DepositFileHome',
    component: DepositFileHome,
    props: true,
    meta: {
      breadCrumb(route) {
        const deposit_name = route.params.deposit_name;
        return [
          {
            to: { name: 'WelcomePage' },
            text: 'Deposit Service'
          },
          {
            to: { name: 'DepositHome' },
            text: 'Deposit Homepage'
          },
          {
            text: 'Selected Deposit: ' + deposit_name,
          }
        ]
      }
    }
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router

Vue.use(VueRouterBackButton, { router })

<template>
  <v-container>
    <NavSidebar />
    <AdminUserList />
  </v-container>
</template>

<script>

export default {
  data: () => ({
  }),
  methods: {
  },
  components: {
    NavSidebar: () => import("@/components/NavSidebar.vue"),
    AdminUserList: () => import("@/components/AdminUserList.vue")
  }
}
</script>

<style>
.logo {
  max-height: 80px;
}
</style>
